import React from 'react';
import {NavLink} from "react-router-dom";

const Coding = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                    <img className="img-fluid rounded mb-3 mb-md-0" src="/img/sysadmin.jpg" width={305} height={305}/>
                </div>
                <div className="col-md-9">
                    <p>
                    <h1>Systems Administration</h1>
                    </p>
                    <p>
                        <i>Systems Administrator</i> first officially became my job title in 1998. I was working as a
                        programmer for a company that developed a service management solution and when the then Systems
                        Administrator left I was offered the role. I had only been there for six months at the time
                        but due to the large mixture of systems the company and its customers used it was
                        very easy to demonstrate knowledge and the perfect environment to do so.
                        The systems included AIX, SCO, DEC Alpha, VAX/VMS, ICL TeamServer and probably lots of others
                        which few people would remember or hear of today.
                    </p>
                    <p>
                    <h2>Linux (and UNIX)</h2>
                    </p>
                    <p>
                        Today I manage hundreds of VMs, Physical Servers and Desktops running Linux. To help with the
                        huge take of maintaining these I try to stick to CentOS for all installations providing services
                        that are managed by me or my team. As we are also supporting researchers, project groups and
                        individual preferred choice of OS on VMs and desktop it also includes Ubuntu, Debian, Fedora,
                        Mint, FreeBSD and others.
                    </p>
                    <p>
                        By ensuring my team has complete control of new systems and then opening up more functionally
                        via firewall rules, sudo etc does help but I certainly would not not call administering
                        these systems where every day a research or project group "needs" to do particular things that
                        may open up a new route for an unauthorised person to gain access to the system an easy task!
                        Luckily most needs don't turn out to be needs after a chat about different options!
                    </p>
                    <p>
                        A few examples central services I provide to our users:

                        <ul>
                            <li>Databases: MySQL/MariaDB, PostgreSQL</li>
                            <li>Directories: OpenLDAP</li>
                            <li>Shared User and Group Storage: NFS and Samba with user and group quotas</li>
                            <li>Web servers/services Apache: PHP owner/group su'ed PHP, proxing to to other serives (Tomcat/Spring etc)</li>
                        </ul>
                    </p>

                    <p>
                        <h2>Windows</h2>
                    </p>
                    <p>
                        I tried to stay away from the area of Windows Systems Admin for years being more comfortable in
                        and enjoying
                        the Linux command line world. There has been such a huge change at Microsoft with
                        incroduction of the PowerShell and a huge acknowledgment and acceptance of Linux. I am not
                        a PowerShell expert but I do use it quite a bit now. VMWare produces toolkits to automate
                        VM and Horizon which I use for the creation and cloning in our VDI environment.
                    </p>
                    <p>
                        I set up a domain with a one-way trust to the University allowing research and development
                        to happen within a domain but behind a firewall. Here I use Group Policies heavily.
                    </p>
                </div>
            </div>

        </div>
    );
};

export default Coding;