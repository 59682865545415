import React from 'react';

const Me = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                        <img className="img-fluid rounded mb-3 mb-md-0" src="/img/face.jpeg" width={305} height={305}/>
                </div>
                <div className="col-md-9">
                    <p>
                    <h1>About me</h1>
                    </p>
                    <p>
                    For the last 20 years I have worked for the Computer Science School at the University of Nottingham,
                    initially employed as the Unix Systems Administrator in 1999 and heading the technical team since 2006.
                    As a small team of 3 though, my work has remained very technically focused and less managerial.
                    </p>

                    <p>
                    Linux administration has been a key part of my jobs for 21 years, which included designing, procuring,
                    implementing and ever since supporting the migration of the school's infrastructure from Sun Solaris to Linux.
                    I maintain and support hundreds of VMs along with the hardware infrastructure.
                    </p>

                    <p>
                    Primarily I install/maintain CentOS, each installation needing to remain secure with the large range
                    of packages/services under constant attacks not just from outside but within the University firewall.
                    </p>

                    <p>
                        One of my recent major projects is the <a href="https://nottingham.cloud">nottingham.cloud</a>. This provides
                    200 VMs of switchable configurations to students for teaching and exams and is now the primary
                    environment for the increasing amount of teaching under Linux. I'm in the process of taking this to
                    300 VMs due to an increase in demand and so am adding new hardware and configurations (including
                    adding Fedora and Ubuntu desktops alongside the existing CentOS ones).
                    </p>

                    <p>
                    The servers are running ESXi and VMWare Horizon provides the client access to the desktop (like VNC
                    but excellent performance, you'd generally never know the session was remote).
                    </p>

                    <p>
                    All need to be secured against student "experiments"!
                    </p>

                    <p>
                        (There's more about my skills/technical interests in the other sections.)
                    </p>

                    <p>
                        <i>16th June 2019</i>
                    </p>
                </div>
            </div>

        </div>
    );
};

export default Me;