import React from 'react';
import {NavLink} from "react-router-dom";

const Infrastucture = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                    <img className="img-fluid rounded mb-3 mb-md-0" src="/img/infrastructure.jpg" width={305} height={305}/>
                </div>
                <div className="col-md-9">
                    <p>
                        <h1>Infrastructure</h1>
                    </p>
                    <p>
                        <h2>Storage</h2>
                    </p>
                    <p>
                        I currently maintain a number of Enterprise storage solutions which I have procured for
                        virtual machine, user data and backup use which includes NetApp filers and HPE Arrays over both
                        fibre and copper. I have also set up and maintained VSAN and ZFS with various server hardware.
                    </p>
                    <p>
                        <h2>Networking</h2>
                    </p>
                    <p>
                        In my current role specialist networking knowledge and experience is crucial, I have set up
                        secure environments for courses which actually teach and encourage students to find and
                        take advantage of vulnerabilities in hardware and software. I have a strong knowledge of
                        routing, VLANS and multicast. I configure Cisco switches using the IOS command line assigning
                        VLANS, trunking and link aggregation.
                    </p>
                </div>
            </div>

        </div>
    );
};

export default Infrastucture;