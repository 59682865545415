import React from 'react';
import logo from './logo.svg';
import './App.css';
import {Button} from 'reactstrap';
import TopNavigation from "./TopNavigation";

import {
    Route,
    NavLink,
    HashRouter
} from "react-router-dom";

import Me from "./sections/me";
import Coding from "./sections/coding";
import Sysadmin from "./sections/sysadmin";
import Virtualisation from "./sections/virtualisation";
import Infrastructure from "./sections/infrastucture";

function App() {
    return (
        <HashRouter>
            <div className="App">
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.9.0/css/all.css"/>
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.9.0/css/v4-shims.css"/>

                <TopNavigation/>
                <main>
                    <Route exact path="/" component={Me}/>
                    <Route exact path="/coding" component={Coding}/>
                    <Route path="/sysadmin" component={Sysadmin}/>
                    <Route path="/virtualisation" component={Virtualisation}/>
                    <Route path="/infrastructure" component={Infrastructure}/>
                    <div className="botdiv">&nbsp;</div>
                </main>
                <footer>
                    <p>Nick Reynolds</p>
                </footer>
            </div>
        </HashRouter>
    );
}

export default App;
