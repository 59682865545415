import React from 'react';
import {NavLink} from "react-router-dom";

const Coding = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                    <img className="img-fluid rounded mb-3 mb-md-0" src="/img/code.jpg" width={305} height={305}/>
                </div>
                <div className="col-md-9">
                    <p>
                    <h1>Coding/Scripting</h1>
                    </p>
                    <p>
                    <h2>Languages</h2>
                    </p>
                    <p>
                                <h3>C/C++</h3>
                            </p>
                            <p>
                                I've programmed using C and C++ since the early 90's. Originally with Borland's Turbo C/C++
                                and then after being introduced to Linux at University around 1994, GCC. At work I've written
                                quite a lot of small utilities for multiuser Linux servers in C and C++ where scripts weren't
                                suitable due to the code needing to be unreadable, examples being a coursework submission system and
                                various database management tools.
                            </p>
                            <p>
                                I've had a lesser experience in the Windows world with Visual Studio although I did write
                                a system to manage a print credit payment/charging system. Most of the clever stuff happened at the Linux
                                side on the queue machines (page counting and charging) but the payment kiosk's had management
                                software that ran on windows with an SQL Server backend so I had to interface the two.
                            </p>

                    <p>
                        <h3>Java</h3>
                    </p>
                    <p>
                        I used to loathe Java when I first came across it (late 90's?) I could not understand why anyone
                        would choose this over C/C++. Horrible slow code that wasn't pre-compiled for the processor
                        that would eventually hang the machine via huge memory usage or CPU time. Things have changed,
                        drastically, I love coding with Java!
                    </p>

                    <p>
                        <h3>Perl</h3>
                    </p>
                    <p>
                        Perl was my scripting language of choice for many years and for anything quick I need to do
                        it still is.
                        I'm pretty sure I must have written far more code in Perl than any other language over the years,
                        virtually everyday at work I'm writing a quick perl script to do something.
                        Sure, people think lots of regular expressions makes code hard to read but
                        personally I would love regular expressions to require as little code in every other language!
                        I now try and avoid using it for anything that anybody else is going to have to use/edit though
                        (reluctantly).
                    </p>
                    <p>
                    </p>
                    <p>
                        <h3>Python</h3>
                    </p>
                    <p>
                        The only time I ever used to do anything with Python was adding functionality to a mailman sever.
                        I do seem to use it quite a bit more now but I'm still not a fan of indentation for code blocks.
                    </p>

                    <p>
                        <h2>Frameworks/Toolkits</h2>
                    </p>
                    <p>
                        <h3>Bootstrap</h3>
                    </p>
                    <p>
                        I love bootstrap as it makes me look like a good frontend web developer. It doesn't stop me
                        being colour blind though (I am definitely more of a backend developer than frontend).
                        This site uses bootstrap. I've certainly used others like material design (Material-UI with React) but I
                        know bootstap far better to create interfaces very quickly.
                    </p>
                    <p>
                        <h3>React</h3>
                    </p>
                    <p>
                        React is great. This is a React site if not a very impressive one. I do a lot with React and
                        a Spring Boot backend as a huge amount of my job is dealing with user data.
                        I am planning on creating a few YouTube videos on working with React and Spring with
                        data exchange, security and authentication (including JWT) and various other things.
                    </p>

                    <p>
                        (I'm not saying React is better than Angular, I've just not used Angular as much.)
                    </p>

                    <p>
                        <h3>Spring/Spring Boot</h3>
                    </p>
                    <p>
                        This is where my big programming interest is currently, I've got a few Spring Boot projects
                        on the go. We currently have a <strike>mess</strike> large amount of sources of user data in
                        various databases (MySQL, LDAP, AD, and the main source of information we only have
                        web access to just with an end user interface!) so one of the projects
                        I am currently working on is to bring this together
                        under a Spring Boot-powered API that can be accessed via a web interface created with React.
                    </p>
                </div>
            </div>

        </div>
    );
};

export default Coding;