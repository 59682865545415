import React from 'react';
import {NavLink} from "react-router-dom";

const Coding = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                    <img className="img-fluid rounded mb-3 mb-md-0" src="/img/virtualisation.jpg" width={305} height={305}/>
                </div>
                <div className="col-md-9">
                    <p>
                        <h1>Virtualisation</h1>
                    </p>
                    <p>
                    <h2>VMWare (ESXi/VCenter/Horizon/VSAN)</h2>
                    </p>
                    <p>
                        I have had experience running VMs on VMWare infrastructure for a number of years. This
                        includes setting up an environment from scratch and virtualising the infrastructure within
                        the School of Computer Science at The University of Nottingham. Servers running
                        both services and major research projects. Some services were also migrated from
                        Sun Solaris to Linux VMs at this time also, such as central databases, home file systems,
                        DNS, DHCP and mailing list (mailman) servers.
                    </p>
                    <p>
                        I've also migrated environments between VCenter Windows Server and Appliance Installs, set up
                        a number of VSAN cluster environments (hardware and software) automated cloning using PowerShell and
                        vRealize Operations Manager. Setup cloning and HA with vCentre/ESXi built in functionality
                        as well as Veeam.
                    </p>
                    <p>
                        I created the <a href={"https://nottingham.cloud"}>nottingham.cloud</a> teaching
                        environment which actually started off as a busy couple of days setting up 40
                        ex-lab Desktop PC's and a trial version of VMWare Horizon enterprise. After a very successful
                        trial I got approval for the licenses and later enterprise level servers to run it.
                    </p>
                </div>
            </div>

        </div>
    );
};

export default Coding;