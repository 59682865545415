import React from 'react';

import {
    Route,
    NavLink,
    HashRouter
} from "react-router-dom";

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
} from 'reactstrap';

export default class TopNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <div>
                <Navbar expand="md" color="faded" dark>
                        <span className="logo-text">nicks.net</span>
                    <NavbarToggler onClick={this.toggle} className="mr-2"/>
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                               <NavLink exact to="/" className="nav-link m-2 menu-item nav-active"><i className="fas fa-user"></i> Me</NavLink>
                            </NavItem>
                            <NavItem>
                               <NavLink to="/coding" className="nav-link m-2 menu-item nav-active"><i className="fas fa-code"></i> Coding/Scripting</NavLink>
                            </NavItem>
                            <NavItem>
                               <NavLink to="/sysadmin" className="nav-link m-2 menu-item"><i class="fab fa-centos"></i> Sys Admin</NavLink>
                            </NavItem>
                            <NavItem>
                               <NavLink to="/virtualisation" className="nav-link m-2 menu-item"><i class="fas fa-server"></i> Virtualisation</NavLink>
                            </NavItem>
                            <NavItem>
                               <NavLink to="/infrastructure" className="nav-link m-2 menu-item"><i class="fas fa-network-wired"></i> Infrastructure</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}
